import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    userFirstName: '',
    userLastName: '',
    email: '',
    uid: '',
    authority: [],
    credits: 0
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer