import React from 'react'

const authRoute = [
    {
        key: 'signIn',
        path: `/sign-in`,
        component: React.lazy(() => import('views/auth/SignIn')),
        authority: [],
        meta: {
            layout: 'simple',
            pageContainerType: 'contained',
            footer: true
        }
    },
]

export default authRoute