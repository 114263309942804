import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {

	const roleMatched = useMemo(() => {
		return authority.some(role => userAuthority.includes(role))
	}, [authority, userAuthority])
    
	
	// isEmpty(userAuthority)
    if (isEmpty(authority) || typeof authority === 'undefined') {
		return !emptyCheck
	}

	return roleMatched
}

export default useAuthority