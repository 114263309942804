import ApiService from './ApiService'

import {
    auth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
  } from '../firebase';


export async function apiSignIn ({email, password}) {
    return await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
}

export async function apiSignUp ({email, password}) {
    return await createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err)
}

export async function apiSignOut () {
    return await signOut(auth).then(user => user).catch(err => err);
}

export async function apiForgotPassword (data) {
    return sendPasswordResetEmail(auth, data.email)
    .then(() => {
        return {
            status:'success'
        }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return {
        status:'failed',
        errorCode,
        errorMessage
    }
    });
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}


